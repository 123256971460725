import React from "react";
import Navbar from "../components/Navbar/Navbar.js";
import NavbarCustomer from "../components/Navbar/NavbarCustomer.js";
import NavbarAdmin from "../components/Navbar/NavbarAdmin.js";
import isAuthenticated from "./auth.js";
import LoginService from "../service/LoginService"

const loginService = LoginService()

function NavbarHelper() {
  if(isAuthenticated()) {
    if(loginService.isCustomer()) {
      return <NavbarCustomer />;
    } else {
      if(loginService.isAdmin()) {
        return <NavbarAdmin />;
      }
    }
  } else {
    return <Navbar />;
  }
}

export default NavbarHelper;
