/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./CardDefault.css";
import "./CardTitle.css";

function CardTitle(props) {
  return (
    <>
      <div className="card_title">
        <div className="cards_info_title" to={props.path}>
          <div className="cards_info_title">
            <h5 className="cards_item_text_title">{props.text}</h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardTitle;
