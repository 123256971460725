import React from "react";
import axios from "../api";
import Loading from "../components/Loading";
import TeamForm from "../components/Forms/TeamForm";
import TeamManager from "../components/Team/TeamManager";

class TeamCustomerHelper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      team: "",
    };
  }

  componentDidMount() {
    this.checkHaveATeam();
  }

  checkHaveATeam() {
    this.setState({
      loading: true,
    });
    axios
      .get("/customer/haveTeam")
      .then((response) => {
        this.setState({
          error: "",
          loading: false,
          team: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      if (this.state.team) {
        return (
          <div>
            {this.state.team.name}
            <TeamManager />
          </div>
        );
      } else {
        return <TeamForm />;
      }
    }
  }
}

export default TeamCustomerHelper;
