import React from "react";
import Loading from "../Loading";
import axios from "../../api";
import { Container, Row, Col } from "react-bootstrap";
import Players from "./Players";
import Managers from "./Managers";

class TeamManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableHead: ["Giocatore", "", ""],
      tableTitle: [],
      tableData: [],
      tableDataDirigenti: [],
      players: [],
      dirigenti: [],
      teamName: ""
    };
  }

  componentDidMount() {
    this.getTeam();
  }

  getTeam() {
    this.setState({
      loading: true,
    });
    axios
      .get("/customer/getTeam")
      .then((response) => {
        this.setState({
          players: response.data.players,
          dirigenti: response.data.leaders,
          teamName: response.data.name
        });
        this.setPlayers();
        this.setDirigenti();
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  setPlayers() {
    const dataTabel = [];
    this.state.players.forEach((player) => {
      const row = [];
      row.push(player.name + " " + player.surname);
      row.push(player.id);
      row.push("");
      dataTabel.push(row);
    });
    this.setState({
      error: "",
      loading: false,
      tableData: dataTabel,
    });
  }

  setDirigenti() {
    const dataTabel = [];
    this.state.dirigenti.forEach((dirigente) => {
      const row = [];
      row.push(dirigente.name + " " + dirigente.surname);
      row.push(dirigente.id);
      row.push("");
      dataTabel.push(row);
    });
    this.setState({
      error: "",
      loading: false,
      tableDataDirigenti: dataTabel,
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <Container>
          <Row className="justify-content-md-center statistic">
            <Col md="12">
              <h2>{this.state.teamName}</h2>
              <a className="file" href="/regolamento.pdf" download>
                Regolamento
              </a>
              <hr />
              <h6 style={{ marginBottom: "20px", marginTop: "20px" }}>
                Ti ricordiamo che puoi aggiungere al massimo 14 giocatori e 2
                dirigenti e che per ogni minorenne è obbligatorio compilare il
                modulo fornito con i documenti dell'iscrizione.
              </h6>
            </Col>
            <Col md="6">
              <Players players={this.state.tableData} />
            </Col>
            <Col md="6">
              <Managers dirigenti={this.state.tableDataDirigenti} />
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default TeamManager;
