import React from "react";
import "../../App.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import TeamDetailPage from "../TeamDetailPage";

function TeamDetail() {
  let query = useQuery();
  let id = query.get("id");
  return (
    <>
      <Container>
        <div className="page-title">
          <h2>Squadra</h2>
        </div>
        <Row className="justify-content-md-center">
          <Col md="8">
            <Card>
              <Card.Body>
                <Card.Text
                  style={{ paddingTop: "20px" }}
                  className="justify-content-md-center"
                >
                  <TeamDetailPage id={id}/>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default TeamDetail;
