import React from 'react'
import { Card, Row, Container, Col } from "react-bootstrap";
import RegistrationForm from '../Login/RegistrationForm';
import CardTitle from '../Card/CardTitle';

function Registration() {
    return (
        <>
      <Container>
        <CardTitle text='Registrati per iscrivere la tua squadra' />
        <Row className="justify-content-md-center statistic">
          <Col md="8">
            <Card>
              <Card.Body>
                <Card.Text className="justify-content-md-center statistic">
                  <RegistrationForm />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
    )
}

export default Registration
