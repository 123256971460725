import React from "react";
import axios from "../../api";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const schema = yup.object().shape({
  minute: yup.number().required("Campo minuti obbligatorio"),
});

class GolForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.populateTable();
  }

  saveFunction(values) {
    this.setState({
      loading: true,
    });
    if (!values.player || values.player === "Seleziona un giocatore") {
      this.setState({
        error: "Seleziona un giocatore",
        loading: false,
      });
    } else {
      this.props.players.forEach((player) => {
        if (player.id.toString() === values.player.toString()) {
          values.player = player;
        }
      });
      axios
        .post("/gol", values)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            error: "Qualcosa è andato storto :) Contattaci se hai problemi",
            loading: false,
          });
        });
    }
  }

  deleteFunction(goalId) {
    this.loading = true;
    document.getElementsByClassName("modal")[0].style.visibility = "hidden";
    document.getElementsByClassName("modal-backdrop")[0].style.visibility =
      "hidden";
    confirmAlert({
      title: "Gol",
      message: "Sei sicuro di voler cancellare il gol?",
      buttons: [
        {
          label: "SI",
          onClick: () => {
            this.setState({
              loading: true,
            });
            axios
              .delete("/gol?id=" + goalId)
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                this.loading = false;
                console.log(error);
              });
          },
        },
        {
          label: "NO",
          onClick: () => {
            document.getElementsByClassName("modal")[0].style.visibility = "";
            document.getElementsByClassName(
              "modal-backdrop"
            )[0].style.visibility = "";
          },
        },
      ],
    });
  }

  populateTable() {
    const players = this.props.players;
    const goals = this.props.goals;
    const dataTabel = [];
    goals.forEach((goal) => {
      const row = [];
      players.forEach((player) => {
        player.goals.forEach((playerGoal) => {
          if (playerGoal.id.toString() === goal.id.toString()) {
            row.push(player.name + " " + player.surname);
          }
        });
      });
      row.push(goal.id);
      row.push(goal.minute + "'");
      dataTabel.push(row);
    });
    this.setState({
      tableData: dataTabel,
      loading: false,
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      const players = this.props.players;
      var minute;
      var diffMins
      if(this.props.match.secondHalf != null && this.props.match.secondHalf === true){
        diffMins = Math.abs(new Date(this.props.match.startSecondHalfDate) - new Date());
        minute = Math.floor(diffMins / 1000 / 60) + 1 + 25;
      } else {
        diffMins = Math.abs(new Date(this.props.match.startDate) - new Date());
        minute = Math.floor(diffMins / 1000 / 60) + 1 ;
      }
      return (
        <>
          <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
              this.setState({ isLoading: true });
              actions.setSubmitting(false);
              this.saveFunction(values);
            }}
            initialValues={{
              player: null,
              minute: minute,
              match: this.props.match,
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit} className="form-style">
                <Form.Group controlId="validationFormikplayer">
                  <Form.Label>Seleziona un giocatore</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      as="select"
                      aria-describedby="inputGroupPrepend"
                      name="player"
                      value={values.player}
                      onChange={handleChange}
                      isInvalid={!!errors.player}
                    >
                      <option value="Seleziona un giocatore">
                        Seleziona un giocatore
                      </option>
                      {players.map(function (player) {
                        return (
                          <option key={player.id} value={player.id}>
                            {player.name} {player.surname}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.player}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <Form.Group controlId="validationFormikName">
                    <Form.Label>Minuto</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="number"
                        placeholder="Minuto"
                        aria-describedby="inputGroupPrepend"
                        name="minute"
                        value={values.minute}
                        onChange={handleChange}
                        isInvalid={!!errors.minute}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.minute}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Group>
                <div className="error-message">{this.state.error}</div>
                <Button variant="primary" type="submit">
                  Salva
                </Button>{" "}
              </Form>
            )}
          </Formik>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "white",
                      width: "40%",
                    }}
                  ></TableCell>
                  <TableCell style={{ textAlign: "center" }}>Minuto</TableCell>
                  <TableCell style={{ textAlign: "center" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableData.map((row) => (
                  <TableRow key={row[1]}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "white",
                        width: "40%",
                        fontSize: "15px",
                      }}
                    >
                      {row[0]}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {row[2]}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Button
                        className="delete"
                        onClick={this.deleteFunction.bind(this, row[1])}
                      >
                        <i class="fas fa-trash-alt delete"></i>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }
  }
}

export default GolForm;
