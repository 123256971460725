import React from "react";
import { Card, Row, Container, Col } from "react-bootstrap";
import ResetPasswordForm from "../Login/ResetPasswordForm";
import { useLocation } from "react-router-dom";

function ResetPassword() {
  let query = useQuery();
  let token = query.get("token");
  return (
    <>
      <Container>
        <div className="page-title">
          <h2>Inserisci la tua nuova password</h2>
        </div>
        <Row className="justify-content-md-center statistic">
          <Col md="8">
            <Card>
              <Card.Body>
                <Card.Text className="justify-content-md-center statistic">
                  <ResetPasswordForm token={token} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default ResetPassword;
