import React from "react";
import "./HeroSection.css";
import "../../App.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function HeroSection() {
  return (
    <div className="hero-container">
      <h1>Rovarè Cup</h1>
      <span>#piùdiuntorneo</span>
      <div className="hero-btns">
        <Link
          to={{
            pathname: "/subscribe",
          }}
        >
          <Button variant="info" size="lg">
            Iscriviti
          </Button>{" "}
        </Link>
        <Link
          to={{
            pathname: "/highlights",
          }}
        >
          <Button variant="light" size="lg">
            Highlights <i className="far fa-play-circle"></i>
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default HeroSection;
