import React from "react";
import { Card, Row, Container, Col } from "react-bootstrap";
import RecuperaPasswordForm from "../Login/RecuperaPasswordForm";

function RecuperaPassword() {
  return (
    <>
      <Container>
        <div className="page-title">
          <h2>Recupera password</h2>
        </div>
        <Row className="justify-content-md-center statistic">
          <Col md="8">
            <Card>
              <Card.Body>
                <Card.Text className="justify-content-md-center statistic">
                  <RecuperaPasswordForm />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RecuperaPassword;
