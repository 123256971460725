import React from "react";
import Cartellini from "../Classifica/Cartellini";

function ClassificaCartellini() {
  return (
    <>
      <Cartellini title="CARTELLINI" />
    </>
  );
}

export default ClassificaCartellini;
