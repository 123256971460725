import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Loading from "../Loading";
import axios from "../../api";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Managers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableHead: ["Giocatore", "", ""],
      tableTitle: [],
      tableData: [],
      dirigenti: [],
    };
  }

  getButtons() {
    if (this.props.dirigenti.length < 2) {
      return (
        <Link
          to={{
            pathname: "/manager",
          }}
        >
          <Button
            style={{ marginTop: "20px", marginBottom: "20px" }}
            variant="primary"
            size="md"
          >
            Nuovo dirigente
          </Button>
        </Link>
      );
    } else {
      return <></>;
    }
  }

  delete(id) {
    confirmAlert({
      title: 'Dirigente',
      message: 'Sei sicuro di voler cancellare questo dirigente?',
      buttons: [
        {
          label: 'SI',
          onClick: () => {
            this.setState({
              loading: true,
            });
            axios
              .delete("/customer/dirigenti?id=" + id)
              .then((response) => {
                this.setState({
                  error: "",
                });
                window.location = "/team";
              })
              .catch((error) => {
                alert("Qualcosa è andato storto :) Contattaci se hai problemi")
                this.setState({
                  loading: false,
                });
              });
          }
        },
        {
          label: 'NO',
        }
      ]
    });
    
  }

  edit(id) {
    window.location = "/manager?id="+id;
  }

  render() {
    const tableData = this.props.dirigenti;
    if (this.state.loading) {
      return <Loading />;
    } else {
      if (tableData.length === 0) {
        return (
          <div>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <div>
                    Nessun dirigente presente
                    <div>
                      <Link
                        to={{
                          pathname: "/manager",
                        }}
                      >
                        <Button
                          variant="primary"
                          size="md"
                          style={{ marginBottom: "20px" }}
                        >
                          Clicca qui per aggiungerlo
                        </Button>
                      </Link>
                    </div>
                  </div>
                </TableHead>
              </Table>
            </TableContainer>
          </div>
        );
      } else {
        return (
          <div>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  I TUOI DIRIGENTI
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center", width: "90%" }}
                    ></TableCell>
                    <TableCell style={{ textAlign: "center" }}></TableCell>
                    <TableCell style={{ textAlign: "center" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row) => (
                    <TableRow key={row[0]}>
                      <TableCell style={{ textAlign: "center", width: "90%" }}>
                        {row[0]}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Button className="edit" onClick={this.edit.bind(this, row[1])}>
                          <i class="fas fa-edit edit"></i>
                        </Button>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Button className="delete" onClick={this.delete.bind(this, row[1])}>
                          <i class="fas fa-trash-alt delete"></i>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {this.getButtons()}
          </div>
        );
      }
    }
  }
}

export default Managers;
