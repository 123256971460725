import React from "react";
import Girone from "./Girone";
import { Container, CardDeck, Row, Col, Card } from "react-bootstrap";
import API from "../../api";
import Loading from "../Loading";
import CardTitle from "../Card/CardTitle";

class Classifica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      mapGroupDay: new Map(),
      loading: false,
      rankings: [],
    };
  }

  componentDidMount() {
    this.getGroups();
  }

  getGroups() {
    this.setState({
      loading: true,
    });
    API.get("group/all")
      .then((response) => {
        this.setState({
          groups: response.data,
        });
        this.setRanking();
      })
      .catch((error) => {
        this.setState({
          error: "Error retrieving data",
          loading: false,
        });
      });
  }

  setRanking() {
    var rank = [];
    if (this.state.groups.length === 0) {
      rank.push(<Girone />);
    } else {
      this.state.groups.forEach((group, i) => {
        rank.push(<Girone group={group} groupId={group.id} />);
      });
    }
    this.setState({
      loading: false,
      rankings: rank,
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      if (this.state.rankings && this.state.rankings.length > 0) {
        return (
          <div>
            <Container>
            <CardTitle text={this.props.title} />
            </Container>
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col md="10">
                  <CardDeck>{this.state.rankings}</CardDeck>
                </Col>
              </Row>
            </Container>
          </div>
        );
      } else {
        return (
          <div>
            <Container>
            <CardTitle text={this.props.title} />
            </Container>
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col md="10">
                  <Card>
                    <Card.Body>
                      <Card.Header className="card-header-empty">
                        Nessun Girone disponibile
                      </Card.Header>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
    }
  }
}

export default Classifica;
