import React from "react";
import "../../App.css";
import { Container, Card, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import CardTitle from "../Card/CardTitle";

class Subscribe extends React.Component {
  render() {
    return (
      <div>
        <Container>
          <CardTitle text="ISCRIVITI" />
          <Row className="justify-content-md-center">
            <Col md="10">
              <Card>
                <Card.Body className="information-card">
                  <Container>
                    <Row className="justify-content-md-center">
                      <Col md="6">
                        <h5 style={{marginTop: "20px"}}>
                          Iscrivi la tua squadra solo dopo averci contattato
                        </h5>
                        {
                          <Link
                              to={{
                                pathname: "/registrazione",
                              }}
                          >
                            <Button variant="primary" size="lg" disable>
                              Clicca qui per iscrivere la tua squadra{" "}
                            </Button>
                          </Link>
                        }
                        <hr/>
                        <h5 style={{marginTop: "20px"}}>
                          Chiamaci o scrivici un'email per qualsisi
                          informazione!
                        </h5>
                        <div>
                          <i className="fas fa-phone"> </i>
                          Marco 340 3705754
                        </div>
                        <div>
                          <i className="fas fa-phone"> </i>
                          Enrico 349 7114426
                        </div>
                        <div>
                          <i className="fas fa-at"> </i>
                          rovarecup@gmail.com
                        </div>

                        <hr/>
                        <h5>
                          In seguito puoi scaricare il regolamento del torneo
                        </h5>
                        <a href="../../../regolamento.pdf" target="_blank">
                          Regolamento
                        </a>
                      </Col>
                      <Col md="6">
                        <Image src="images/iscriviti.png" rounded fluid/>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Subscribe;
