import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
      <h2 className='cards_title'>NEWS</h2>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
        <CardItem
              src='images/2024.png'
              text='ROVARÈ CUP 2024⚽️ ISCRIZIONI APERTE‼️ Dal 10 al 28 Giugno
              La corsa al titolo ha ufficialmente inizio 🏆
              Come prima e più di prima, non fartelo raccontare, vi aspettiamo! 🤩💪🏻🥇🍺🍔
              Termine ultimo iscrizioni 25/05 !'
              label='Rovarè Cup 2024 ⚽️🏟️'
              path='/services'
            />
          <CardItem
              src='images/finale-2023.png'
              text='È stata una finale incredibile, piena di goal e di emozioni, 
              una partita infinita ma che alla fine vede trionfare ai rigori la formazione guidata dai mister Brazzo e Vettori e che dopo l’amarezza della finale persa lo scorso anno regala la gioia più grande a capitan Vio che può alzare al cielo di Rovarè il trofeo più ambito🏆'
              label='Cantine Padroncini vince l’edizione 2023 della ROVARÈ CUP 🥇'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
          <CardItem
              src='images/2023.jpg'
              text='Appuntamento a sera venerdì 07/07 dalle ore 20.00⚽️🏟️ 
              Dopo tre settimane intensissime e piene di duelli sportivi e sano divertimento, domani sera scopriremo assieme il vincitore della Rovarè Cup 2023 🏆 
              Emozioni allo stato puro, vi aspettiamo 🔥🤩'
              label='Chioschetto ristoro attivo tutta la serata🍔🍺🍟'
              path='/'
            />
          <CardItem
              src='images/2023.png'
              text='Vivi insieme a noi serate estive ricche di sport e divertimento 🤩🍔🍺
              Non fartelo raccontare 😎'
              label='ROVARÈ CUP 2023 🤩'
              path='/sign-up'
            />
          <CardItem
              src='images/1-2022.jpeg'
              text='Ci siete mancati e non vediamo l’ora di trascorrere assieme a voi serate estive ricche di sport, divertimento e buon cibo ⚽️🎉🍔
              Termine ultimo per iscrizioni squadre il 02/06. 🏟'
              label='Aperte le iscrizioni per la ROVARÈ CUP 2022 🤩'
              path='/sign-up'
            />
          </ul>
          <ul className='cards__items'>
          <CardItem
              src='images/2.jpg'
              text='Rovarè Cup 2021 è stata annullata causa Covid-19. Vi aspettiamo il prossimo anno. A presto ⚽️🍺❤️'
              label='Giugno 2021'
              path='/services'
            />
          <CardItem
              src='images/1.png'
              text='Torneremo e tornerà la spensieratezza e le serate all’insegna dello sport e del divertimento, insieme a tutti voi che siete la spinta per tutto questo!'
              label='Gennaio 2021'
              path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
