import React from "react";
import API from "../../api";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";
import { Link } from "react-router-dom";

const schema = yup.object().shape({
  username: yup
    .string()
    .email("Inserisci una mail valida")
    .max(255)
    .required("Campo email obbligatorio"),
  password: yup.string().required("Campo password obbligatorio").min(6).max(40),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Le password non corrispondono")
    .required("Campo conferma password obbligatorio"),
  mobilePhone: yup.number().required("Campo numero obbligatorio"),
  terms: yup
    .bool()
    .required()
    .oneOf([true], "I termini devono essere accettate"),
});

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      info: ""
    };
  }

  registrationFunction(values) {
    this.setState({
      error: "",
      loading: true,
      info: ""
    });
    API.post("/api/auth/signupCustomer", values)
      .then((response) => {
        this.setState({
          error: "",
          loading: false,
          info: "Registrazione effettuata con successo. Vai alla login"
        });
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto, contattaci :(",
          loading: false,
          info: ""
        });
      });
  }

  getButtons() {
    if (this.state.error) {
      return (
        <>
          <div className="error-message">{this.state.error}</div>
          <Button variant="primary" type="submit">
            Registrati
          </Button>
        </>
      );
    } else {
      if (this.state.info) { 
        return (
        <Link
        to={{
          pathname: "/login",
        }}
      >
        <div style={{marginBottom:"20px"}}>Utente registrato :)</div>
        <Button variant="primary" type="submit">
          Vai alla login
        </Button>
      </Link>
      );} else {
      return (
        <Button variant="primary" type="submit">
          Registrati
        </Button>
      );
    }}
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else 
    if (this.state.info) { 
      return  this.getButtons();
    } else {
      return (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={(values, actions) => {
            this.setState({ isLoading: true });
            actions.setSubmitting(false);
            this.registrationFunction(values);
          }}
          initialValues={{
            username: "",
            password: "",
            passwordConfirmation: "",
            mobilePhone: "",
            terms: false,
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="form-style">
              <Form.Group controlId="validationFormikUsername">
                <Form.Label>Email</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    aria-describedby="inputGroupPrepend"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikPassword">
                <Form.Label>Password</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    aria-describedby="inputGroupPrepend"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikPasswordConfirmation">
                <Form.Label>Conferma password</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="password"
                    placeholder="Conferma password"
                    aria-describedby="inputGroupPrepend"
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    isInvalid={!!errors.passwordConfirmation}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passwordConfirmation}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikMobilePhone">
                <Form.Label>Numero di telefono</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="number"
                    placeholder="Numero di telefono"
                    aria-describedby="inputGroupPrepend"
                    name="mobilePhone"
                    value={values.mobilePhone}
                    onChange={handleChange}
                    isInvalid={!!errors.mobilePhone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobilePhone}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  required
                  name="terms"
                  label="Ricevuta l'informativa sull'utilizzo dei dati personali ai sensi dell'Art. 10 della Legge n° 675/96, consento al loro trattamento nella misura necessaria per il perseguimento degli scopi strettamente legati alla manifestazione.
                  Ogni dato inserito all'interno del sito rovarecup.com sarà utilizzato esclusivamente per l'iscrizione all'assicurazione personale che coprirà ogni singolo atleta durante tutto lo svolglimento del torneo RovareCup 2024 o per comunicazioni interne alla manifestazione stessa. I dati registrati all'interno
                  del sito rovarecup.com sono conservati per il tempo strettamente necessario al raggiungimento del fine anteriormente indicato. "
                  onChange={handleChange}
                  isInvalid={!!errors.terms}
                  feedback={errors.terms}
                  id="validationFormik0"
                />
              </Form.Group>
              {this.getButtons()}
            </Form>
          )}
        </Formik>
      );
    }
  }
}

export default RegistrationForm;
