import React from "react";
import YouTube from "react-youtube";
import "./Video.css";

function Video(props) {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  };
  return (
    <>
      <div className="video-container">
        <div className="video-placeholder">
          <div className="video-placeholder-cover">
            <YouTube videoId={props.videoId} opts={opts} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Video;
