import React from "react";
import "../../App.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import TeamManagerAdmin from "../Admin/TeamManagerAdmin";
import { useLocation } from "react-router-dom";

function TeamManagerEdit() {
  let query = useQuery();
  let id = query.get("id");
  return (
    <>
      <Container>
        <div className="page-title">
          <h2>Gestione squadra</h2>
        </div>
        <Row className="justify-content-md-center">
          <Col md="8">
            <Card>
              <Card.Body>
                <Card.Text
                  style={{ paddingTop: "20px" }}
                  className="justify-content-md-center"
                >
                  <TeamManagerAdmin id={id}/>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default TeamManagerEdit;
