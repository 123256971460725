const LoginService = () => {
    const currentUserSubject = JSON.parse(localStorage.getItem('user'));

    function logout() {
        localStorage.removeItem('user');
        window.location = "/";
    }

    function isCustomer() {
        return currentUserSubject && currentUserSubject.role === 'ROLE_CUSTOMER'
    }

    function isAdmin() {
        return currentUserSubject && currentUserSubject.role === 'ROLE_ADMIN'
    }

    return {
        currentUserSubject,
        logout,
        isCustomer,
        isAdmin
    }
}

export default LoginService