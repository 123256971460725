import React from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import Loading from "../Loading";
import Moment from "react-moment";
import API from "../../api";
import BlinkText from "../BlinkText";
import "./MatchDetailAdmin.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import GolForm from "../Forms/GolForm";
import CardForm from "../Forms/CardForm";
import BestForm from "../Forms/BestForm";
import RigoriForm from "../Forms/RigoriForm";
import MyStopwatch from "../Stopwatch";
import DatePicker from "react-datepicker";

class MatchDetailAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      matchId: [],
      match: [],
      matchDetail: [],
      homePlayers: [],
      guestPlayers: [],
      homeGoals: [],
      guestGoals: [],
      onShotValue: [],
      outShotValue: [],
      cornerValue: [],
      yellowValue: [],
      redValue: [],
      show: false,
      modalComponent: [],
      modalTitle: "",
      showRigori: false,
      startDate: new Date(),
    };
  }

  componentDidMount() {
    this.getMatch(this.props.matchId);
  }

  getMatch(id) {
    this.setState({
      loading: true,
    });
    API.get("match?id=" + id)
      .then((response) => {
        this.getStatisticMatch(this.props.matchId, response.data);
        this.setState({
          matchDetail: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }

  getStatisticMatch(id, match) {
    this.setState({
      loading: true,
    });
    API.get("match/statistics?id=" + id)
      .then((response) => {
        this.setState({
          loading: false,
          match: response.data,
          homePlayers: this.getPlayers(
            response.data.homePlayers,
            true,
            response.data
          ),
          guestPlayers: this.getPlayers(
            response.data.guestPlayers,
            false,
            response.data
          ),
          homeGoals: this.getGoals(
            response.data.homePlayers,
            response.data.homeGoals
          ),
          guestGoals: this.getGoals(
            response.data.guestPlayers,
            response.data.guestGoals
          ),
          onShotValue: this.getProgressBar(
            response.data.homeShotsOnGol,
            response.data.guestShotsOnGol,
            false,
            false,
            "Tiri in porta",
            match
          ),
          outShotValue: this.getProgressBar(
            response.data.homeShotsOutGol,
            response.data.guestShotsOutGol,
            false,
            false,
            "Tiri fuori",
            match
          ),
          cornerValue: this.getProgressBar(
            response.data.homeCorners,
            response.data.guestCorners,
            false,
            false,
            "Corner",
            match
          ),
          yellowValue: this.getProgressBar(
            response.data.homeCards,
            response.data.guestCards,
            true,
            true,
            "Ammonizioni"
          ),
          redValue: this.getProgressBar(
            response.data.homeCards,
            response.data.guestCards,
            true,
            false,
            "Espulsioni"
          ),
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }

  getGoals(inputPlayers, inputGoals) {
    var goals = [];
    const map1 = new Map();
    inputGoals.sort(function (a, b) {
      return a.minute - b.minute;
    });
    inputGoals.forEach((goal, keys) => {
      inputPlayers.forEach((player, keys) => {
        var minute = "";
        player.goals.forEach((goalPlayer, keys) => {
          if (goalPlayer.id === goal.id) {
            minute = goal.minute;
          }
        });
        if (minute !== "") {
          var name = player.name;
          var minutesSorted = minute + "' ";
          name = name.charAt(0) + ".";
          if (map1.get(name + player.surname) !== undefined) {
            minutesSorted = map1.get(name + player.surname) + minutesSorted;
            map1.set(name + player.surname, minutesSorted);
          } else {
            map1.set(name + player.surname, minutesSorted);
          }
        }
      });
    });
    map1.forEach((value, key) => {
      goals.push(
        <div key={key}>
          {key} {value}
        </div>
      );
    });

    return goals;
  }

  getProgressBar(home, guest, iscard, isYellow, title, match) {
    var homeValue = home;
    var guestValue = guest;
    if (iscard === true) {
      homeValue = 0;
      guestValue = 0;
      home.forEach((card, keys) => {
        if (isYellow === true) {
          if (card.type === "Ammonizione") {
            homeValue = homeValue + 1;
          }
        } else {
          if (card.type === "Espulsione") {
            homeValue = homeValue + 1;
          }
        }
      });
      guest.forEach((card, keys) => {
        if (isYellow === true) {
          if (card.type === "Ammonizione") {
            guestValue = guestValue + 1;
          }
        } else {
          if (card.type === "Espulsione") {
            guestValue = guestValue + 1;
          }
        }
      });
    }
    var progressBar = [];
    progressBar.push(
      <Container key={title} fluid>
        <Row className="justify-content-md-center statistic">
          <Col md="12">{title}</Col>
          <Col md="2">{homeValue}</Col>
          <Col md="4">
            <Button
              hidden={iscard}
              variant="success"
              onClick={this.plusAction.bind(this, title, match, true)}
            >
              +
            </Button>{" "}
          </Col>
          <Col md="4">
            <Button
              hidden={iscard}
              variant="success"
              onClick={this.plusAction.bind(this, title, match, false)}
            >
              +
            </Button>{" "}
          </Col>
          <Col md="2">{guestValue}</Col>
        </Row>
      </Container>
    );
    return progressBar;
  }

  getMatchDetail(match) {
    var date = <Moment subtract={{ hours: 2 }}>{match.date}</Moment>;
    if (match.matchType === "Gruppo") {
      return (
        <div>
          <div>{date}</div>
          <div>{match.group.name}</div>
          {this.getStatusButton(this.state.matchDetail)}
        </div>
      );
    } else {
      return (
        <div>
          <div>{date}</div>
          <div>{match.matchType}</div>
          {this.getStatusButton(this.state.matchDetail)}
        </div>
      );
    }
  }

  getManageTempo(match) {
    if (this.state.matchDetail.secondHalf !== true) {
      return (
        <div>
          <DatePicker
            locale="it"
            showTimeSelect
            dateFormat="Pp"
            selected={this.state.startDate}
            onChange={(date) => this.setState({ startDate: date })}
          />
          <Button
            variant="primary"
            style={{ marginBottom: "10px", marginTop: "10px" }}
            onClick={this.setMatchDateStart.bind(this, this.state.matchDetail)}
          >
            Setta data e ora inizio partita
          </Button>
          <br></br>
          <Button
            variant="primary"
            style={{ marginBottom: "10px", marginTop: "10px" }}
            onClick={this.startSecondHalf.bind(this, this.state.matchDetail)}
          >
            Inizio Secondo Tempo
          </Button>
        </div>
      );
    } else {
      return <></>;
    }
  }
  getDateOrResult(match) {
    var value = [];
    if (match.status === "Finita" || match.status !== "InCorso") {
      if (match.penalty === true) {
        let display = match.homeResult + "-" + match.guestResult;
        let displayPenalty =
          "(" +
          match.penaltyHomeGol +
          "-" +
          match.penaltyGuestGol +
          ")" +
          " dcr";
        value.push(
          <div key={match.id}>
            <div>{display}</div>
            <div style={{ marginTop: "-13px" }}>{displayPenalty}</div>
          </div>
        );
      } else {
        let display = match.homeResult + "-" + match.guestResult;
        value.push(<div key={match.id}>{display}</div>);
      }
    } else {
      let display = match.homeResult + "-" + match.guestResult;
      value.push(<BlinkText textData={display} />);
    }
    return value;
  }

  getPlayers(inputPlayers, isHome, match) {
    var players = [];
    inputPlayers.forEach((player, keys) => {
      var name = player.name;
      var goals = [];
      var cards = [];
      var bests = [];
      name = name.charAt(0) + ".";
      player.goals.forEach((goal, keys) => {
        if (isHome) {
          match.homeGoals.forEach((homeGoal, key) => {
            if (homeGoal.id === goal.id) {
              goals.push(
                <Image
                  className="icon2"
                  key={homeGoal.id}
                  src="images/gol.png"
                />
              );
            }
          });
        } else {
          match.guestGoals.forEach((guestGol, key) => {
            if (guestGol.id === goal.id) {
              goals.push(
                <Image
                  className="icon2"
                  key={guestGol.id}
                  src="images/gol.png"
                />
              );
            }
          });
        }
      });
      player.best.forEach((best, keys) => {
        if (isHome) {
          match.homeBest.forEach((homeBest, key) => {
            if (homeBest.id === best.id) {
              bests.push(
                <Image
                  className="icon2"
                  key={homeBest.id}
                  src="images/star.png"
                />
              );
            }
          });
        } else {
          match.guestBest.forEach((guestBest, key) => {
            if (guestBest.id === best.id) {
              bests.push(
                <Image
                  className="icon2"
                  key={guestBest.id}
                  src="images/star.png"
                />
              );
            }
          });
        }
      });
      player.cards.forEach((card, keys) => {
        if (isHome) {
          match.homeCards.forEach((homeCard, key) => {
            if (homeCard.id === card.id) {
              if (card.type === "Ammonizione") {
                cards.push(
                  <Image
                    className="icon2"
                    key={homeCard.id}
                    src="images/yellow.png"
                  />
                );
              } else {
                cards.push(
                  <Image
                    className="icon2"
                    key={homeCard.id}
                    src="images/red.png"
                  />
                );
              }
            }
          });
        } else {
          match.guestCards.forEach((guestCard, key) => {
            if (guestCard.id === card.id) {
              if (card.type === "Ammonizione") {
                cards.push(
                  <Image
                    className="icon2"
                    key={guestCard.id}
                    src="images/yellow.png"
                  />
                );
              } else {
                cards.push(
                  <Image
                    className="icon2"
                    key={guestCard.id}
                    src="images/red.png"
                  />
                );
              }
            }
          });
        }
      });
      players.push(
        <div key={player.id} className="players">
          {name} {player.surname} {goals} {cards} {bests}
        </div>
      );
    });
    return players;
  }

  getStatusButton(match) {
    const status = match.status;
    if (status === "InCorso") {
      return (
        <>
          <Button
            variant="primary"
            style={{ marginBottom: "10px", marginTop: "10px" }}
            onClick={this.completedMatch.bind(this, match, "Finita")}
          >
            Finita
          </Button>{" "}
          <Button
            variant="primary"
            style={{ marginBottom: "10px", marginTop: "10px" }}
            onClick={this.handleShowRigori.bind(this, match)}
          >
            Finita ai rigori
          </Button>{" "}
        </>
      );
    } else if (status === "Programmata") {
      return (
        <Button
          variant="primary"
          style={{ marginBottom: "10px", marginTop: "10px" }}
          onClick={this.startMatch.bind(this, match, "InCorso")}
        >
          Inizia
        </Button>
      );
    } else if (status === "Finita") {
      return (
        <Button
          variant="primary"
          style={{ marginBottom: "10px", marginTop: "10px" }}
          onClick={this.completedMatch.bind(this, match, "InCorso")}
        >
          Riapri
        </Button>
      );
    }
  }

  startMatch(match, status) {
    confirmAlert({
      title: "Parita",
      message:
        "Sei sicuro di voler mettere lo stato " +
        status +
        " per questa partita?",
      buttons: [
        {
          label: "SI",
          onClick: () => {
            this.setState({
              loading: true,
            });
            API.put("/match/completedMatch?matchStatus=" + status, match)
              .then(() => {
                // if status is inCorso, set the start date
                console.log(status === "InCorso" + status);
                if (status === "InCorso") {
                  console.log(this.props.matchId + "start");
                  localStorage.setItem(
                    this.props.matchId + "start",
                    new Date()
                  );
                }
                window.location.reload();
              })
              .catch((error) => {
                this.setState({
                  loading: false,
                });
                console.log(error);
              });
          },
        },
        {
          label: "NO",
        },
      ],
    });
  }

  startSecondHalf(match, status) {
    confirmAlert({
      title: "Parita",
      message: "Inizia il secondo tempo?",
      buttons: [
        {
          label: "SI",
          onClick: () => {
            this.setState({
              loading: true,
            });
            localStorage.setItem(match.id + "secondHalf", true);
            API.put("/match/startSecondHalf", match)
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                this.setState({
                  loading: false,
                });
                console.log(error);
              });
          },
        },
        {
          label: "NO",
        },
      ],
    });
  }

  setMatchDateStart(match, status) {
    match.startDate = this.state.startDate;
    confirmAlert({
      title: "Parita",
      message: "Settare la data di inizio?",
      buttons: [
        {
          label: "SI",
          onClick: () => {
            this.setState({
              loading: true,
            });
            localStorage.setItem(match.id + "start", this.state.startDate);
            API.put("/match/setStartDate", match)
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                this.setState({
                  loading: false,
                });
                console.log(error);
              });
          },
        },
        {
          label: "NO",
        },
      ],
    });
  }

  completedMatch(match, status) {
    confirmAlert({
      title: "Partita",
      message:
        "Sei sicuro di voler mettere lo stato " +
        status +
        " per questa partita?",
      buttons: [
        {
          label: "SI",
          onClick: () => {
            this.setState({
              loading: true,
            });
            API.put("/match/completedMatch?matchStatus=" + status, match)
              .then(() => {
                if (match.status === "InCorso") {
                  API.put("/ranking/match?matchId=" + match.id)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch((error) => {
                      this.setState({
                        loading: false,
                      });
                      console.log(error);
                    });
                } else {
                  API.put("/ranking/reopenMatch?matchId=" + match.id)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch((error) => {
                      this.setState({
                        loading: false,
                      });
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
        {
          label: "NO",
        },
      ],
    });
  }

  addBest(player) {
    let best;
    best.player = player;
    best.match = this.match;
    this.setState(this.loading, true);
    API.post("/best", this.best)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        this.setState(this.loading, false);
        console.log(error);
      });
  }

  plusAction(type, match, isHome) {
    this.setState({
      loading: true,
    });
    if (type === "Tiri in porta") {
      API.put("/match/addShotIn?isHome=" + isHome, match)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            error: "Qualcosa è andato storto :) Contattaci se hai problemi",
            loading: false,
          });
        });
    } else if (type === "Tiri fuori") {
      API.put("/match/addShotOut?isHome=" + isHome, match)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            error: "Qualcosa è andato storto :) Contattaci se hai problemi",
            loading: false,
          });
        });
    } else {
      API.put("/match/addCorners?isHome=" + isHome, match)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            error: "Qualcosa è andato storto :) Contattaci se hai problemi",
            loading: false,
          });
        });
    }
  }

  handleShowRigori() {
    this.setState({
      showRigori: true,
    });
  }

  handleShow(type, players, element) {
    let component = [];
    let title = "";
    if (type === "Gol") {
      component = (
        <GolForm
          match={this.state.matchDetail}
          players={players}
          goals={element}
        />
      );
      title = "Gol";
    } else if (type === "Cartellino") {
      component = (
        <CardForm
          match={this.state.matchDetail}
          players={players}
          cards={element}
        />
      );
      title = "Cartellino";
    } else {
      component = (
        <BestForm
          match={this.state.matchDetail}
          players={players}
          bests={element}
        />
      );
      title = "Miglior in campo";
    }
    this.setState({
      show: true,
      modalComponent: component,
      modalTitle: title,
    });
  }

  handleClose() {
    this.setState({
      show: false,
    });
  }

  handleCloseRigori() {
    this.setState({
      showRigori: false,
    });
  }

  render() {
    const match = this.state.match;
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <div>
          <Container fluid style={{ paddingTop: "30px" }}>
            <Row className="justify-content-md-center">
              <Col md="10" sm="12">
                <Card>
                  <Card.Body>
                    <Card.Text className="card-text-result">
                      <Container fluid>
                        <Row className="justify-content-md-center">
                          <Col className="team-statistiche">
                            <div>{match.homeTeam}</div>
                          </Col>
                          <Col className="risultato">
                            <div>{this.getDateOrResult(match)}</div>
                          </Col>
                          <Col className="team-statistiche">
                            <div>{match.guestTeam}</div>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center rowGoals">
                          <Col>
                            <div>{this.state.homeGoals}</div>
                          </Col>
                          <Col>
                            <div>
                              {this.getMatchDetail(match)}
                              <MyStopwatch
                                match={this.state.matchDetail}
                                status={match.status}
                              />
                              {this.getManageTempo(match)}
                            </div>
                          </Col>
                          <Col>
                            <div>{this.state.guestGoals}</div>
                          </Col>
                        </Row>
                        <Container fluid style={{ marginBottom: "20px" }}>
                          <Row className="justify-content-md-center">
                            <Col sm="6" style={{ textAlign: "left" }}>
                              <div style={{ marginTop: "20px" }}>
                                <Button
                                  variant="info"
                                  onClick={this.handleShow.bind(
                                    this,
                                    "Gol",
                                    match.homePlayers,
                                    match.homeGoals
                                  )}
                                >
                                  Gol
                                </Button>{" "}
                                <Button
                                  variant="info"
                                  onClick={this.handleShow.bind(
                                    this,
                                    "Cartellino",
                                    match.homePlayers,
                                    match.homeCards
                                  )}
                                >
                                  Cartellino
                                </Button>{" "}
                                <Button
                                  variant="info"
                                  onClick={this.handleShow.bind(
                                    this,
                                    "Best",
                                    match.homePlayers,
                                    match.homeBest
                                  )}
                                >
                                  Miglior in campo
                                </Button>{" "}
                              </div>
                            </Col>
                            <Col sm="6" style={{ textAlign: "right" }}>
                              <div style={{ marginTop: "20px" }}>
                                <Button
                                  variant="info"
                                  onClick={this.handleShow.bind(
                                    this,
                                    "Gol",
                                    match.guestPlayers,
                                    match.guestGoals
                                  )}
                                >
                                  Gol
                                </Button>{" "}
                                <Button
                                  variant="info"
                                  onClick={this.handleShow.bind(
                                    this,
                                    "Cartellino",
                                    match.guestPlayers,
                                    match.guestCards
                                  )}
                                >
                                  Cartellino
                                </Button>{" "}
                                <Button
                                  variant="info"
                                  onClick={this.handleShow.bind(
                                    this,
                                    "Best",
                                    match.guestPlayers,
                                    match.guestBest
                                  )}
                                >
                                  Miglior in campo
                                </Button>{" "}
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Container>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md="4" sm="12" className="players">
                <Card style={{ opacity: 0.9 }}>
                  <Card.Body>
                    <Card.Text>{this.state.homePlayers}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4" sm="12">
                <Card style={{ opacity: 0.9 }}>
                  <Card.Body>
                    <Card.Text style={{ textAlign: "center" }}>
                      {this.state.onShotValue}
                      {this.state.outShotValue}
                      {this.state.cornerValue}
                      {this.state.yellowValue}
                      {this.state.redValue}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4" sm="12" className="players">
                <Card style={{ opacity: 0.9 }}>
                  <Card.Body>
                    <Card.Text>{this.state.guestPlayers}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <Modal
            id="modal"
            show={this.state.show}
            onHide={this.handleClose.bind(this)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.modalComponent}</Modal.Body>
          </Modal>
          <Modal
            id="modalRigori"
            show={this.state.showRigori}
            onHide={this.handleCloseRigori.bind(this)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Rigori</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <RigoriForm match={this.state.matchDetail} />
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  }
}

export default MatchDetailAdmin;
