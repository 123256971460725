import React from "react";
import "../../App.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import TeamAdmin from "../Admin/TeamAdmin";

function TeamManager() {
  return (
    <>
      <Container>
        <div className="page-title">
          <h2>Gestione squadre</h2>
        </div>
        <Row className="justify-content-md-center">
          <Col md="8">
            <Card>
              <Card.Body>
                <Card.Text
                  style={{ paddingTop: "20px" }}
                  className="justify-content-md-center"
                >
                  <TeamAdmin />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TeamManager;
