import React from "react";
import "../../App.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import MatchAdmin from "../Admin/MatchAdmin";
import CardTitle from "../Card/CardTitle";

function MatchManager() {
  return (
    <>
      <Container>
      <CardTitle text='PARTITE' />
        <Row className="justify-content-md-center">
          <Col md="12">
            <Card>
              <Card.Body>
                <Card.Text style={{paddingTop: "20px"}}className="justify-content-md-center">
                <MatchAdmin />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MatchManager;
