import React from "react";
import Loading from "../Loading";
import axios from "../../api";
import {
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  Button,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import LoginService from "../../service/LoginService";
import BlinkText from "../BlinkText";
import Moment from "react-moment";
import "../Classifica/Girone.css";

const loginService = LoginService();

class MatchesTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      matches: [],
      tableData: [],
      tableDataFilter: [],
      filter: "",
      teamId: []
    };
  }

  componentDidMount() {
    this.getMatch();
  }

  getMatch() {
    this.setState({
      loading: true,
    });
    axios
      .get("customer/team/statistics")
      .then((response) => {
        const dataTabel = [];
        response.data.forEach((match) => {
          const row = [];
          let status = match.status ? match.status : "Programmata";
          let teams;
          if (!match.homeTeam && !match.guestTeam) {
            teams = "Da definire";
          } else {
            teams =
              (match.homeTeam ? match.homeTeam.toUpperCase() : "Da definire") +
              " vs " +
              (match.guestTeam ? match.guestTeam.toUpperCase() : "Da definire");
          }
          row.push(match.matchId);
          row.push(teams);
          if (status === "InCorso") {
            row.push(<BlinkText textData={match.homeResult} />);
            row.push(<BlinkText textData={match.guestResult} />);
          } else {
            row.push(match.homeResult);
            row.push(match.guestResult);
          }
          row.push(
            match.date ? (
              <Moment subtract={{ hours: 2 }}>{match.date}</Moment>
            ) : (
              ""
            )
          );
          if (match.matchType === "Gruppo") {
            row.push(match.group.name);
          } else {
            row.push(match.matchType);
          }
          row.push(match.video);
          dataTabel.push(row);
        });
        this.setState({
          loading: false,
          tableData: dataTabel,
          tableDataFilter: dataTabel,
          matches: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          error: "Error retrieving data",
          loading: false,
        });
      });
  }

  filter(value) {
    this.setState({ filter: value });
    if (value === "Tutte le partite") {
      this.setState({ tableDataFilter: this.state.tableData });
    } else {
      if (value === "Fase finale Euro Cup") {
        const result = this.state.tableData.filter(
          (match) => match[5] && match[5].includes("Euro Cup")
        );
        this.setState({ tableDataFilter: result });
      } else if (value === "Fase finale Rovare Cup") {
        const result = this.state.tableData.filter(
          (match) => match[5] && match[5].includes("Rovarè Cup")
        );
        this.setState({ tableDataFilter: result });
      } else if (value === "Gruppo A") {
        const result = this.state.tableData.filter(
          (match) => match[5] && match[5].includes("Gruppo A")
        );
        this.setState({ tableDataFilter: result });
      } else {
        const result = this.state.tableData.filter(
          (match) => match[5] && match[5].includes("Gruppo B")
        );
        this.setState({ tableDataFilter: result });
      }
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      if (this.state.matches.length > 0) {
        return (
          <Container>
            <Row className="justify-content-md-center statistic">
              <Col md="12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            backgroundColor: "white",
                          }}
                        ></TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Risultato
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Data
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Tipo
                        </TableCell>
                        <TableCell hidden={!loginService.isAdmin()}></TableCell>
                        <TableCell hidden={!loginService.isAdmin()}></TableCell>
                        <TableCell hidden={!loginService.isAdmin()}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.tableDataFilter.map((row) => (
                        <TableRow key={row[0]}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "white",
                              width: "40%",
                              fontSize: "15px",
                              textAlign: "center",
                            }}
                          >
                            <Link
                              to={{
                                pathname:
                                  row[1] === "Da definire" ? "" : "/match",
                                state: { matchId: row[0] },
                              }}
                            >
                              {row[1]}
                            </Link>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row[2]} - {row[3]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row[4]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row[5]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <Button
                              className="delete"
                              href={row[6]}
                              target="_blank"
                            >
                              <i class="fas fa-video delete"></i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </Row>
          </Container>
        );
      } else {
        return (
          <Container>
            <Row className="justify-content-md-center statistic">
              <Col md="12">
                <Card>
                  <Card.Body>
                    <Card.Header className="card-header-empty">
                      Nessun Partita disponibile
                    </Card.Header>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        );
      }
    }
  }
}

export default MatchesTeam;
