import React from "react";
import axios from "../../api";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";

const schema = yup.object().shape({
  name: yup.string().max(255).required("Campo nome obbligatorio"),
});

class TeamForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  saveFunction(values) {
    this.setState({
      loading: true,
    });
    axios.post("/customer/team", values)
      .then((response) => {
        this.setState({
          error: "",
          loading: false,
        });
        window.location.reload()
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  getButtons() {
    if (this.state.error) {
      return (
        <>
          <div className="error-message">{this.state.error}</div>
          <Button variant="primary" type="submit">
            Salva
          </Button>{" "}
        </>
      );
    } else {
      return (
        <>
          <Button variant="primary" type="submit">
            Salva
          </Button>{" "}
        </>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <Formik
          validationSchema={schema}
          onSubmit={(values, actions) => {
            this.setState({ isLoading: true });
            actions.setSubmitting(false);
            this.saveFunction(values);
          }}
          initialValues={{
            name: "",
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="form-style">
              <Form.Group controlId="validationFormikName">
                <Form.Label>Nome della squadra</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="string"
                    placeholder="Nome"
                    aria-describedby="inputGroupPrepend"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {this.getButtons()}
            </Form>
          )}
        </Formik>
      );
    }
  }
}

export default TeamForm;
