import React from "react";
import { useLocation } from "react-router-dom";
import MatchLive from "../MatchLive";

function MatchDetailPage() {
    let query = useQuery();
  let matchId = query.get("matchId");
  return (
    <>
      <MatchLive matchId={matchId}/>
    </>
  );
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

export default MatchDetailPage;
