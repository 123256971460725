import React from "react";
import ReactLoading from "react-loading";
import { Container, Row } from "react-bootstrap";

export default class Loading extends React.Component {
  render() {
    return (
      <Container fluid>
        <Row className="justify-content-md-center">
          <ReactLoading type="bubbles" color="#23406b" />
        </Row>
      </Container>
    );
  }
}
