import React from "react";
import { useStopwatch } from "react-timer-hook";
import { useState } from "react";
import API from "../api";

function MyStopwatch(props) {
  const date = props.match.startDate;
  const [time, setTime] = useState("");
  var { seconds, minutes, isRunning } = useStopwatch({
    autoStart: date !== null,
  });
  var dateFuture = new Date();

  var dateMatch;

  if (props.match.secondHalf === true) {
    dateMatch = new Date(props.match.startSecondHalfDate);
  } else {
    dateMatch = new Date(props.match.startDate);
  }

  var secondsDiff = Math.floor((dateFuture - dateMatch) / 1000);
  var minutesDiff = Math.floor(secondsDiff / 60);
  var hours = Math.floor(minutesDiff / 60);
  var days = Math.floor(hours / 24);
  hours = hours - days * 24;
  minutesDiff = minutesDiff - days * 24 * 60 - hours * 60;
  secondsDiff =
  secondsDiff - days * 24 * 60 * 60 - hours * 60 * 60 - minutesDiff * 60;

  if (props.match.secondHalf === true) {
    minutes = minutesDiff + 25;
    seconds = secondsDiff;
  } else {
  if (date === null) {
    minutes = 0;
    seconds = 0;
  } else {
    minutes = minutesDiff;
    seconds = secondsDiff;
    }
  }

  const handleSubmit = (event) => {
    var newDate = `${time}`;
    localStorage.setItem(props.matchId + "start", new Date(newDate));
    event.preventDefault();
  };

  if (props.status === "Finita") {
    return <div style={{ textAlign: "center" }}></div>;
  }

  if (
    props.match.secondHalf === true ||
    props.status !== "InCorso"
  ) {
    return (
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "40px" }}>
          <span>{minutes}</span>:<span>{seconds}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "40px" }}>
          <span>{minutes}</span>:<span>{seconds}</span>
        </div>
      </div>
    );
  }
}

export default MyStopwatch;
