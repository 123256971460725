/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { Dropdown } from "react-bootstrap";

import LoginService from "../../service/LoginService";

const loginService = LoginService();

function NavbarAdmin() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 1200) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const logout = () => {
    loginService.logout();
    window.location = "/";
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="images/logo.png" className="img-logo" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="/calendar"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Calendario
              </Link>
            </li>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">Classifiche</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/classifiche">
                  <Link
                    to="/classifiche"
                    style={{ color: "black" }}
                    onClick={closeMobileMenu}
                  >
                    Gironi
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item href="/bomber">
                  <Link
                    to="/bomber"
                    style={{ color: "black" }}
                    onClick={closeMobileMenu}
                  >
                    Bomber
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item href="/cartellini">
                  <Link
                    to="/cartellini"
                    style={{ color: "black" }}
                    onClick={closeMobileMenu}
                  >
                    Disciplina
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <li className="nav-item">
              <Link
                to="/gironeAdmin"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Gestione gruppi
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/partite"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Partite
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/teamsAdmin"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Squadre
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-links" onClick={logout}>
                <i className="fas fa-sign-out-alt" />
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default NavbarAdmin;
