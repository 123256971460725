import React from "react";
import API from "../../api";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";
import { Link } from "react-router-dom";


const schema = yup.object().shape({
  username: yup
    .string()
    .email("Inserisci una mail valida")
    .max(255)
    .required("Campo email obbligatorio"),
  password: yup.string().required("Campo password obbligatorio"),
});

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  loginFunction(values) {
    this.setState({
      loading: true,
    });
    API.post("/api/auth/signin", values)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        this.setState({
          error: "",
        });
        if(response.data.role === 'ROLE_CUSTOMER'){
          window.location = "/team";
        } else {
          window.location = "/";
        }
      })
      .catch((error) => {
        this.setState({
          error: "Credenziali errate",
          loading: false,
        });
      });
  }

  getButtons() {
    if (this.state.error) {
      return (
        <>
          <div className="error-message">{this.state.error}</div>
          <Button variant="primary" type="submit">
            Login
          </Button>{" "}
          <Link
            to={{
              pathname: "/registrazione",
            }}
          >
            <Button variant="secondary">Registrazione</Button>{" "}
          </Link>
          <Link
            to={{
              pathname: "/recupera-password",
            }}
          >
            <Button variant="danger">Recupera password</Button>{" "}
          </Link>
        </>
      );
    } else {
      return (
        <>
          <Button variant="primary" type="submit">
            Login
          </Button>{" "}
          <Link
            to={{
              pathname: "/registrazione",
            }}
          >
            <Button variant="secondary">Registrazione</Button>{" "}
          </Link>
        </>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={(values, actions) => {
            this.setState({ isLoading: true });
            actions.setSubmitting(false);
            this.loginFunction(values);
          }}
          initialValues={{
            username: "",
            password: "",
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="form-style">
              <Form.Group controlId="validationFormikUsername">
                <Form.Label>Email</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="email"
                    placeholder="Username"
                    aria-describedby="inputGroupPrepend"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikPassword">
                <Form.Label>Password</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    aria-describedby="inputGroupPrepend"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {this.getButtons()}
            </Form>
          )}
        </Formik>
      );
    }
  }
}

export default LoginForm;
